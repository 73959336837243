import React, { useState } from "react"
import Layout from "../components/layout"
// import UserForm from "../components/user-form"
// import Login from "../components/login"

import { Container, Row, Col, Card, Form, Button } from "react-bootstrap"

const LoginPage = ({ location }) => {
  const [data, setData] = useState({
    name: "",
    linkedIn: "",
    email: "",
    password: "",
    error: null,
  })

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  console.log(location)
  return (
    <Layout>
      <section className="py-4">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card>
                <Card.Body>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Your Work Email Address</Form.Label>
                    <Form.Control
                      name="email"
                      type="email"
                      placeholder="name@work.com"
                      value={data.email}
                      onChange={handleChange}
                    />
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Create a Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="password"
                      value={data.password}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <Login location={ location } page={"update-status"}>
            { (user, handleShow, setModalContent) => 
              (
              <UserForm handleShow={ handleShow } setModalContent={ setModalContent } user={ user }/> 
              )
            }
          </Login> */}
        </Container>
      </section>
    </Layout>
  )
}

export default LoginPage
